var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"high-frequency"},[_c('el-dialog',{attrs:{"title":"高频操作限制","visible":_vm.dialogVisible,"close-on-click-modal":false,"width":"600px"},on:{"close":_vm.close}},[_c('p',{staticStyle:{"color":"#ff687b","font-size":"14px"}},[_vm._v(" 说明：以下操作将针对全平台所有回收商，请谨慎操作 ")]),_c('p',{staticClass:"box-title mt-10"},[_vm._v("导表高频限制")]),_c('div',{staticClass:"flex mb-10"},[_c('el-switch',{staticClass:"mr-10",model:{value:(_vm.switch1),callback:function ($$v) {_vm.switch1=$$v},expression:"switch1"}}),_c('p',{staticStyle:{"color":"#ff687b","font-size":"14px"}},[_vm._v(" 启用后，将限制回收端导表、回收商后台高频导表功能 ")])],1),(_vm.switch1)?_c('div',[_c('div',{staticClass:"flex"},[_c('span',{staticStyle:{"flex-shrink":"0"}},[_vm._v("生效日期：")]),_c('el-date-picker',{attrs:{"size":"small","picker-options":_vm.pickerOptions,"value-format":"yyyy-MM-dd","type":"daterange","range-separator":"至","start-placeholder":"开始日期","end-placeholder":"结束日期"},model:{value:(_vm.valueTime),callback:function ($$v) {_vm.valueTime=$$v},expression:"valueTime"}})],1),_c('div',{staticClass:"flex mt-10"},[_c('span',{staticStyle:{"flex-shrink":"0"}},[_vm._v("生效时间段：")]),_c('div',{staticClass:"selectTime"},[_c('el-time-select',{staticClass:"timeselect",attrs:{"size":"small","placeholder":"开始时间","picker-options":{
              start: '00:00',
              step: '00:15',
              end: '24:00',
              maxTime: _vm.inDayEndTime,
            }},model:{value:(_vm.inDayStartTime),callback:function ($$v) {_vm.inDayStartTime=$$v},expression:"inDayStartTime"}})],1),_c('span',{staticStyle:{"margin":"0 5px"}},[_vm._v("~")]),_c('div',[_c('el-time-select',{staticClass:"timeselect",attrs:{"size":"small","placeholder":"结束时间","picker-options":{
              start: '00:00',
              step: '00:15',
              end: '24:00',
              minTime: _vm.inDayStartTime,
            }},model:{value:(_vm.inDayEndTime),callback:function ($$v) {_vm.inDayEndTime=$$v},expression:"inDayEndTime"}})],1)])]):_vm._e(),_c('p',{staticClass:"box-title mt-10"},[_vm._v("统计高频限制")]),_c('div',{staticClass:"flex mb-10"},[_c('el-switch',{staticClass:"mr-10",model:{value:(_vm.switch2),callback:function ($$v) {_vm.switch2=$$v},expression:"switch2"}}),_c('p',{staticStyle:{"color":"#ff687b","font-size":"14px"}},[_vm._v(" 启用后，回收端统计页面将无法使用本月、上月、选择时间查询 ")])],1),(_vm.switch2)?_c('div',[_c('div',{staticClass:"flex"},[_c('span',{staticStyle:{"flex-shrink":"0"}},[_vm._v("生效日期：")]),_c('el-date-picker',{attrs:{"size":"small","picker-options":_vm.pickerOptions,"value-format":"yyyy-MM-dd","type":"daterange","range-separator":"至","start-placeholder":"开始日期","end-placeholder":"结束日期"},model:{value:(_vm.valueTime2),callback:function ($$v) {_vm.valueTime2=$$v},expression:"valueTime2"}})],1),_c('div',{staticClass:"flex mt-10"},[_c('span',{staticStyle:{"flex-shrink":"0"}},[_vm._v("生效时间段：")]),_c('div',{staticClass:"selectTime"},[_c('el-time-select',{staticClass:"timeselect",attrs:{"size":"small","placeholder":"开始时间","picker-options":{
              start: '00:00',
              step: '00:15',
              end: '24:00',
              maxTime: _vm.inDayEndTime2,
            }},model:{value:(_vm.inDayStartTime2),callback:function ($$v) {_vm.inDayStartTime2=$$v},expression:"inDayStartTime2"}})],1),_c('span',{staticStyle:{"margin":"0 5px"}},[_vm._v("~")]),_c('div',[_c('el-time-select',{staticClass:"timeselect",attrs:{"size":"small","placeholder":"结束时间","picker-options":{
              start: '00:00',
              step: '00:15',
              end: '24:00',
              minTime: _vm.inDayStartTime2,
            }},model:{value:(_vm.inDayEndTime2),callback:function ($$v) {_vm.inDayEndTime2=$$v},expression:"inDayEndTime2"}})],1)])]):_vm._e(),(_vm.operateUser.userAccount)?_c('p',{staticClass:"bottom-staff"},[_vm._v(" 最近修改："+_vm._s(_vm.operateUser.userName)+"-"+_vm._s(_vm.operateUser.userAccount)+"（"+_vm._s(_vm.operateUser.updateTime)+"） ")]):_vm._e(),_c('span',{staticClass:"dialog-footer",attrs:{"slot":"footer"},slot:"footer"},[_c('el-button',{on:{"click":_vm.close}},[_vm._v("取消")]),_c('el-button',{attrs:{"type":"primary"},on:{"click":_vm.submit}},[_vm._v("确定")])],1)]),_c('el-dialog',{attrs:{"title":"操作提示","visible":_vm.tipsShow,"close-on-click-modal":false,"width":"500px"},on:{"update:visible":function($event){_vm.tipsShow=$event},"closed":function($event){_vm.command = ''}}},[_c('div',{staticClass:"tip-sure"},[_vm._v("是否确认修改高频操作限制？")]),_c('div',{staticClass:"flex mb-10"},[_c('div',{staticStyle:{"width":"88px"}},[_vm._v("动态口令：")]),_c('el-input',{attrs:{"placeholder":"请输入谷歌动态口令","oninput":"this.value = this.value.replace(/[^0-9a-zA-Z]/ig,'')"},model:{value:(_vm.command),callback:function ($$v) {_vm.command=$$v},expression:"command"}})],1),_c('span',{staticClass:"dialog-footer",attrs:{"slot":"footer"},slot:"footer"},[_c('el-button',{nativeOn:{"click":function($event){_vm.tipsShow = false}}},[_vm._v("取消")]),_c('el-button',{attrs:{"type":"primary"},nativeOn:{"click":function($event){return _vm.sureSubmit.apply(null, arguments)}}},[_vm._v("确定")])],1)])],1)
}
var staticRenderFns = []

export { render, staticRenderFns }