<template>
  <div class="high-frequency">
    <el-dialog
      title="高频操作限制"
      :visible="dialogVisible"
      :close-on-click-modal="false"
      width="600px"
      @close="close"
    >
      <p style="color: #ff687b; font-size: 14px">
        说明：以下操作将针对全平台所有回收商，请谨慎操作
      </p>
      <p class="box-title mt-10">导表高频限制</p>
      <div class="flex mb-10">
        <el-switch class="mr-10" v-model="switch1"></el-switch>
        <p style="color: #ff687b; font-size: 14px">
          启用后，将限制回收端导表、回收商后台高频导表功能
        </p>
      </div>
      <div v-if="switch1">
        <div class="flex">
          <span style="flex-shrink: 0;">生效日期：</span>
          <el-date-picker
            size="small"
            v-model="valueTime"
            :picker-options="pickerOptions"
            value-format="yyyy-MM-dd"
            type="daterange"
            range-separator="至"
            start-placeholder="开始日期"
            end-placeholder="结束日期"
          >
          </el-date-picker>
        </div>
        <div class="flex mt-10">
          <span style="flex-shrink: 0;">生效时间段：</span>
          <div class="selectTime">
            <el-time-select
              size="small"
              class="timeselect"
              placeholder="开始时间"
              v-model="inDayStartTime"
              :picker-options="{
                start: '00:00',
                step: '00:15',
                end: '24:00',
                maxTime: inDayEndTime,
              }"
            >
            </el-time-select>
          </div>
          <span style="margin: 0 5px">~</span>
          <div>
            <el-time-select
              size="small"
              class="timeselect"
              placeholder="结束时间"
              v-model="inDayEndTime"
              :picker-options="{
                start: '00:00',
                step: '00:15',
                end: '24:00',
                minTime: inDayStartTime,
              }"
            >
            </el-time-select>
          </div>
        </div>
      </div>
      <p class="box-title mt-10">统计高频限制</p>
      <div class="flex mb-10">
        <el-switch class="mr-10" v-model="switch2"></el-switch>
        <p style="color: #ff687b; font-size: 14px">
          启用后，回收端统计页面将无法使用本月、上月、选择时间查询
        </p>
      </div>
      <div v-if="switch2">
        <div class="flex">
          <span style="flex-shrink: 0;">生效日期：</span>
          <el-date-picker
            size="small"
            v-model="valueTime2"
            :picker-options="pickerOptions"
            value-format="yyyy-MM-dd"
            type="daterange"
            range-separator="至"
            start-placeholder="开始日期"
            end-placeholder="结束日期"
          >
          </el-date-picker>
        </div>
        <div class="flex mt-10">
          <span style="flex-shrink: 0;">生效时间段：</span>
          <div class="selectTime">
            <el-time-select
              size="small"
              class="timeselect"
              placeholder="开始时间"
              v-model="inDayStartTime2"
              :picker-options="{
                start: '00:00',
                step: '00:15',
                end: '24:00',
                maxTime: inDayEndTime2,
              }"
            >
            </el-time-select>
          </div>
          <span style="margin: 0 5px">~</span>
          <div>
            <el-time-select
              size="small"
              class="timeselect"
              placeholder="结束时间"
              v-model="inDayEndTime2"
              :picker-options="{
                start: '00:00',
                step: '00:15',
                end: '24:00',
                minTime: inDayStartTime2,
              }"
            >
            </el-time-select>
          </div>
        </div>
      </div>
      <p class="bottom-staff" v-if="operateUser.userAccount">
        最近修改：{{ operateUser.userName }}-{{
          operateUser.userAccount
        }}（{{operateUser.updateTime}}）
      </p>
      <span slot="footer" class="dialog-footer">
        <el-button @click="close">取消</el-button>
        <el-button type="primary" @click="submit">确定</el-button>
      </span>
    </el-dialog>
    <el-dialog
      title="操作提示"
      :visible.sync="tipsShow"
      :close-on-click-modal="false"
      width="500px"
      @closed="command = ''"
    >
      <div class="tip-sure">是否确认修改高频操作限制？</div>
      <div class="flex mb-10">
        <div style="width: 88px">动态口令：</div>
        <el-input
          v-model="command"
          placeholder="请输入谷歌动态口令"
          oninput="this.value = this.value.replace(/[^0-9a-zA-Z]/ig,'')"
        ></el-input>
      </div>
      <span slot="footer" class="dialog-footer">
        <el-button @click.native="tipsShow = false">取消</el-button>
        <el-button type="primary" @click.native="sureSubmit">确定</el-button>
      </span>
    </el-dialog>
  </div>
</template>

<script>
import _api from "@/utils/request";
export default {
  name: "HighFrequency",
  props: {
    dialogVisible: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      switch1: false,
      switch2: false,
      valueTime: [],
      inDayStartTime: "",
      inDayEndTime: "",
      valueTime2: [],
      inDayStartTime2: "",
      inDayEndTime2: "",

      pickerOptions: {
        disabledDate(time) {
          const today = new Date();
          today.setHours(0, 0, 0, 0); // 设置时间为今天的零点
          return time.getTime() < today.getTime();
        },
      },
      tipsShow: false,
      highFrequencyList: [],
      command: "",
      operateUser: {
        userAccount: "",
        userName: "",
        updateTime: "",
      },
    };
  },
  created() {
    this.getInfo();
  },
  methods: {
    close() {
      this.$emit("close");
    },
    getInfo() {
      _api.restrictInfo().then((res) => {
        if (res.code === 1) {
          // 接口返回就这样，不能动态显示配置，只能这样才好处理
          this.highFrequencyList = res.data.merchantHighFrequencyInfoVos || [];
          this.highFrequencyList.forEach((item) => {
            if (item.restrictCode === "Merchant_Import") {
              this.switch1 = item.isEnable || false;
              this.valueTime = item.restrictDate?item.restrictDate.split(",") : [];
              this.inDayStartTime = item.restrictTime
                ? item.restrictTime.split(",")[0]
                : "";
              this.inDayEndTime = item.restrictTime
                ? item.restrictTime.split(",")[1]
                : "";
            }
            if (item.restrictCode === "Merchant_Statics") {
              this.switch2 = item.isEnable || false;
              this.valueTime2 = item.restrictDate?item.restrictDate.split(",") : [];
              this.inDayStartTime2 = item.restrictTime
                ? item.restrictTime.split(",")[0]
                : "";
              this.inDayEndTime2 = item.restrictTime
                ? item.restrictTime.split(",")[1]
                : "";
            }
          });
          this.operateUser = {
            userAccount: res.data.userAccount,
            userName: res.data.userName,
            updateTime: res.data.updateTime,
          };
        }
      });
    },
    submit() {
      console.log(this.valueTime,'valueTime');
      if(this.switch1){
        if(!this.valueTime){
          return this.$message.error("请选择导表高频限制的生效日期");
        }
        if(!this.inDayStartTime || !this.inDayEndTime){
          return this.$message.error("请选择导表高频限制的生效时间段");
        }
      }
      if(this.switch2){
        if(!this.valueTime2){
          return this.$message.error("请选择统计高频限制的生效日期");
        }
        if(!this.inDayStartTime2 || !this.inDayEndTime2){
          return this.$message.error("请选择统计高频限制的生效时间段");
        }
      }
      this.tipsShow = true;
    },
    sureSubmit() {
      if(!this.command.trim()){
        return this.$message.error("请输入动态口令");
      }
      this.highFrequencyList.forEach(item => {
        if(item.restrictCode === 'Merchant_Import'){
          item.isEnable = this.switch1
          item.restrictDate = this.valueTime?this.valueTime.join(','):""
          item.restrictTime = this.inDayStartTime && this.inDayEndTime ? this.inDayStartTime + ',' + this.inDayEndTime : ""
        }
        if(item.restrictCode === 'Merchant_Statics'){
          item.isEnable = this.switch2
          item.restrictDate = this.valueTime2?this.valueTime2.join(','):""
          item.restrictTime = this.inDayStartTime2 && this.inDayEndTime2 ? this.inDayStartTime2 + ',' + this.inDayEndTime2 : ""
        }
      })

      let params = {
        command: this.command,
        merchantHighFrequencyInfoVos: this.highFrequencyList
      }
      _api.updateHighFrequency(params).then(res => {
        if(res.code === 1){
          this.$message.success(res.msg || '操作成功')
          this.close()
        }
      })
    },
  },
};
</script>

<style lang="scss" scoped>
.high-frequency {
  .mt-10 {
    margin-top: 10px;
  }
  .mb-10 {
    margin-bottom: 10px;
  }
  .mr-10 {
    margin-right: 10px;
  }
  .flex {
    display: flex;
    align-items: center;
  }

  .box-title {
    position: relative;
    padding-left: 20px;
    margin-bottom: 10px;
    color: #333333;
    font-size: 16px;
    font-weight: bold;
    &::before {
      position: absolute;
      top: 0;
      bottom: 0;
      left: 0;
      margin: auto;
      content: " ";
      width: 4px;
      height: 16px;
      background: #0981ff;
      border-radius: 2px;
    }
  }
  .bottom-staff {
    margin-top: 50px;
  }
  .tip-sure {
    margin-top: 10px;
    color: #333333;
    font-size: 16px;
    text-align: center;
    margin-bottom: 20px;
  }
}
</style>
